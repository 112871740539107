<template>
  <div>
    <helloWorld />
    <!-- banner -->
    <!-- <div class="type-banner">
      <img src="@/assets/bac.webp" alt="">
    </div> -->
    <div style="display: flex;max-width: 1200px;margin: auto;">
      <div class="free-left">
        <el-collapse v-model="activeName">
          <el-collapse-item title="专辑名称" name="1">
            <el-input v-model="queryParams.title" placeholder="请输入专辑名称" size="mini" @keyup.enter.native="handleQuery"></el-input>
            <el-button size="mini" style="float:right;margin-top:6px;margin-bottom:6px" @click="handleQuery">搜索</el-button>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div v-if="total == 0" class="phone-size" style="width:80%">
          <el-empty description="暂无专辑" style="margin-top:30px"></el-empty>
        </div>
      <!-- <p class="genre">{{obj.title}}</p>
      <p class="genre-desc">随着移动互联网的快速发展和新媒体平台的风靡,人们对文化艺术的追求早已不限制在实体方面,数字藏品的价值被越来越多的人接受。让文化IP以数字藏品形式在公益、科技、文化、音乐等不同群体中内流转,向更广泛元宇宙应用场景推进。</p> -->
      <div v-else class="phone-size" style="width:80%">
        <div class="fZZKFU">
          <div class="fZZKFU-item" v-for="(item, index) in list" :key="index" @click="toAuthor(item)">
            <img class="fZZKFU-bac" :src="item.coverPic" alt="">
            <div style="text-align:center">
              <img v-if="item.creator.avatar!=''" class="fZZKFU-avatar" :src="item.avatar" alt="">
              <img  class="fZZKFU-avatar" v-else src="@/assets/avatar.jpg" alt="">
            </div>
            <p class="fZZKFU-name">{{item.title}}</p>
            <p class="from">来自<span @click.stop="toUser(item)">{{item.creator.nickname}}</span></p>
            <p class="fZZKFU-desc">{{item.desc}}</p>
          </div>
        </div>
        
        <pagination
          v-show="total>0"
          :total="total"
          :page-sizes="[12, 24, 36, 48, 50]"
          :page.sync="queryParams.PageNum"
          :limit.sync="queryParams.PageSize"
          @pagination="getList"
        />
      </div>
    </div>
  </div>
</template>
<script>
import helloWorld from '@/components/tabBar'
import {getAlbum} from '../../api/index'
import { mapGetters } from 'vuex'
export default {
  name: 'List',
  components: {
    helloWorld
  },
  mounted() {
    if (!this.accessToken || this.accessToken == '') {
      this.$router.push('./')
      return
    }
    this.getList()
  },
  computed: {
    ...mapGetters(['accessToken', 'userCode'])
  },
  data() {
    return {
      env: process.env.VUE_APP_BASE_API,
      total: 0,
      queryParams: {
        PageNum: 1,
        PageSize: 12,
        title: ''
      },
      radio1: '1',
      activeName: ['1'],
      obj: {},
      list: []
    }
  },
  watch: {
    $route () {
      this.$router.go(0)
    }
  },
  methods: {
    toUser(item) {
      this.$router.push('../user?userCode=' + item.creator.userCode + '&&id='+item.creator.id)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.PageNum = 1;
      this.getList();
    },
    getList() {
      getAlbum(this.queryParams).then(res => {
        if (res.code == 0) {
          this.list = res.data.list
          this.total = res.data.total
          for (var s = 0; s < this.list.length; s++) {
            this.list[s].coverPic = this.env + this.list[s].coverPic
            if (this.list[s].creator.avatar != '') {
              this.list[s].avatar = this.env + this.list[s].creator.avatar
            }
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    toAuthor(row) {
      this.$router.push('./author?title=' + row.title + '&albumId=' + row.id)
    }
  }
}
</script>
<style>
.free-left .el-radio__inner {
  display: none;
}
.free-left .el-radio {
  margin-right: 0;
}
</style>
<style scoped>
.free-left {
  width: 18%;
  padding:30px 20px;
  border-right: 1px solid #f8f8f8;
}
.type-banner img {
  width: 100%;
  height: 150px;
}
.genre {
  text-align: center;
  line-height: 50px;
  font-size: 26px;
  font-weight: bold;
}
.genre-desc {
  color: #666;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 1px;
  max-width: 800px;
  margin: auto;
  margin-top: 10px;
}
.fZZKFU {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 1180px;
  margin: auto;
  margin-top: 20px;
  width: 100%;
}
.fZZKFU-item {
  margin: 20px 14px;
  padding-bottom: 10px;
  border: 1px solid #eee;
  border-radius: 10px;
  overflow: hidden;
}
.fZZKFU-bac {
  width: 100%;
  cursor: pointer;
  height: 200px;
  object-fit: cover;
}
.fZZKFU-item:hover {
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
  margin-top: 16px;
}
.fZZKFU-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-top: -32px;
  cursor: pointer;
}
.fZZKFU-name {
  text-align: center;
  font-size: 14px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  width: 80%;
  margin: auto;
  cursor: pointer;
}
.from {
  text-align: center;
  font-size: 12px;
  line-height: 26px;
  color: #999;
  margin-top: 6px;
}
.from span {
  color: rgb(32, 129, 226);
  margin-left: 4px;
  cursor: pointer;
}
.fZZKFU-desc {
  font-size: 12px;
  line-height: 20px;
  color: #999;
  width: 80%;
  margin: auto;
  word-break: break-all;
text-overflow: ellipsis;
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
margin-top: 6px;
cursor: pointer;
}
@media screen and (max-width: 720px){
  .fZZKFU{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 720px) and (max-width: 900px){
  .fZZKFU{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>